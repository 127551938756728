// path: '/contact'
import React from 'react';

const ContactPage = () => {
	return (
		<div >
        contact
		</div>
	);
};

export default ContactPage;
